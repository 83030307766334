bcl.s.loading = {
  props: {
    dynamicClass: ".bhg-loading.dynamicLoading",
    defaultLoading: null,
    loader: null,
  },

  init(baseDom) {
    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-loadings.css", bcl.s.loading.props.dynamicClass);

    const baseFind = baseDom || document;
    const authorURLS = /localhost|author-barcelo-dev-65.adobecqms.net|author-barcelo-stage-65.adobecqms.net|author-barcelo-prod-65.adobecqms.net/;
    const loadingDataset = baseFind.body.dataset.loading;

    if (!loadingDataset) {
      console.log("No loading dataset found on body element");
      return;
    }

    const loadingURL = authorURLS.test(location.hostname) ? `${loadingDataset}?wcmmode=disabled` : loadingDataset;

    bcl.s.loading.props.defaultLoading = loadingURL;

    if (bcl.s.loading.props.defaultLoading) {
      bcl.ajax.getRequest(null, bcl.s.loading.props.defaultLoading, bcl.s.loading.saveLoading, null, null, null, false);
    }
  },

  loadNewLoader(url) {
    if (!url) {
      console.error("No URL provided for loading new loader");
      return;
    }

    bcl.ajax.getRequest(bcl.s.loading.props.loader, url);
  },

  saveLoading(request) {
    if (!request || !request.response) {
      console.error("Invalid request or response in saveLoading");
      return;
    }

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = request.response;

    const realLoader = tempDiv.querySelector(bcl.s.loading.props.dynamicClass);

    if (!realLoader) {
      console.error(`Could not find '${bcl.s.loading.props.dynamicClass}' in the received HTML`);
      return;
    }

    bcl.s.loading.props.loader = realLoader.cloneNode(true);
  },
};

bcl.preInit.push(bcl.s.loading.init);
