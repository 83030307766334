/* eslint-disable @typescript-eslint/no-use-before-define */
const deleteUnnecessaryLoaders = function () {
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            const loaders = node.matches(".component-loader") ? [node] : node.querySelectorAll(".component-loader");

            loaders.forEach((loader) => {
              if (!loader.closest(".dynamicComponent")) {
                loader.remove();
              }
            });
          }
        });
      }
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  window.cleanupObserver = function () {
    observer.disconnect();
  };
};

const checkDynamicLoaders = function () {
  deleteUnnecessaryLoaders();

  const globalLoaders = document.querySelectorAll("#global-loader");
  if (globalLoaders.length === 0) {
    return;
  }

  const visibleSet = new Set();
  let allLoadersHidden = false;

  const intersectingLoaders = function () {
    return (
      Array.from(document.querySelectorAll(".component-loader")).filter((loader) => {
        const rect = loader.getBoundingClientRect();
        return rect.top < window.innerHeight && rect.bottom > 0;
      }).length > 0
    );
  };

  if (!intersectingLoaders()) {
    allLoadersHidden = true;
    globalLoaders.forEach((loader) => loader.remove());
    return;
  }

  const handleVisibilityChange = function () {
    if (visibleSet.size === 0 && !intersectingLoaders() && !allLoadersHidden) {
      allLoadersHidden = true;
      globalLoaders.forEach((loader) => loader.remove());
      observer.disconnect();
      mutationObserver.disconnect();
    }
  };

  const observer = new IntersectionObserver(
    function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          visibleSet.add(entry.target);
        } else {
          visibleSet.delete(entry.target);
        }
      });
      handleVisibilityChange();
    },
    { threshold: 0 },
  );

  const mutationObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      mutation.addedNodes.forEach(function (node) {
        if (node.classList?.contains("component-loader")) {
          observer.observe(node);
        }
      });

      mutation.removedNodes.forEach(function (node) {
        if (node.classList?.contains("component-loader")) {
          visibleSet.delete(node);
        }
      });
    });
    handleVisibilityChange();
  });

  mutationObserver.observe(document.body, { childList: true, subtree: true });

  document.querySelectorAll(".component-loader").forEach(function (loader) {
    observer.observe(loader);
  });
};

export { checkDynamicLoaders };
