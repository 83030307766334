const domOptimizations = function () {
  //------- 1. Control destination list content ----------//

  document.querySelectorAll(".destinations-list").forEach((destinationsList) => {
    const hiddenDestinationsMap = new Map();
    const destinationItems = destinationsList.querySelectorAll(".destinations-list__content-item");

    destinationItems.forEach((item) => {
      const content = item.querySelector(".accordion-content");
      if (content) {
        hiddenDestinationsMap.set(item, content);
        content.remove();
      }
    });

    destinationsList.addEventListener("click", (event) => {
      const item = event.target.closest(".destinations-list__content-item");
      if (item && hiddenDestinationsMap.has(item)) {
        const content = hiddenDestinationsMap.get(item);
        item.querySelector(".accordion-item")?.appendChild(content);
        hiddenDestinationsMap.delete(item);
      }
    });
  });

  //------- 2. Control market-targeting content ----------//

  document.addEventListener("readystatechange", (event) => {
    if (event.target.readyState === "interactive" || event.target.readyState === "complete") {
      document.querySelectorAll(".market-targeting").forEach((marketTargeting) => {
        const cmpTabs = marketTargeting.querySelector(".cmp-tabs.cmp-tab-JS");
        if (!cmpTabs) {
          return;
        }

        const tabs = [...cmpTabs.querySelector(".cmp-tabs__tablist").querySelectorAll('[role="tab"]')];
        const panels = [...cmpTabs.querySelectorAll(":scope > [role='tabpanel']")];

        tabs.forEach((tab, i) => {
          tab.setAttribute("data-panel-index", i);
        });
        panels.forEach((panel, i) => {
          panel.setAttribute("data-panel-index", i);
        });

        const hiddenPanelsMap = new Map();

        panels.forEach((panel) => {
          const isActive = panel.classList.contains("cmp-tabs__tabpanel--active");
          if (!isActive) {
            const parent = panel.parentNode;
            hiddenPanelsMap.set(panel, parent);
            parent.removeChild(panel);
          }
        });

        cmpTabs.addEventListener("click", (event) => {
          const clickedTab = event.target.closest('[role="tab"]');
          if (!clickedTab) {
            return;
          }

          const panelIndex = clickedTab.getAttribute("data-panel-index");
          if (panelIndex == null) {
            return;
          }

          const panelToRestore = [...hiddenPanelsMap.keys()].find((panel) => {
            return panel.getAttribute("data-panel-index") === panelIndex;
          });

          if (panelToRestore) {
            const parent = hiddenPanelsMap.get(panelToRestore);
            parent.appendChild(panelToRestore);
            hiddenPanelsMap.delete(panelToRestore);

            panelToRestore.classList.add("cmp-tabs__tabpanel--active");
            panelToRestore.setAttribute("aria-hidden", "false");

            panels.forEach((p) => {
              if (p !== panelToRestore) {
                p.classList.remove("cmp-tabs__tabpanel--active");
                p.setAttribute("aria-hidden", "true");
              }
            });
          }
        });
      });
    }
  });

  //-------------------------------------------------------//
};

export { domOptimizations };
